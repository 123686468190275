html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.content {
  line-height: 1.5;
  flex-grow: 1;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.container {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.layout-body {
  flex: 1;
  min-height: 0;
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px)
}

.content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;

  .screen-x-small & {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;
}
.dx-scrollview-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.title{
  font-family: auto !important;
  font-weight: 400 !important;
  text-align: center !important;
  font-size: 25px !important
}

.dx-datagrid .dx-row > td{
    text-align: center !important;
  }
  .text-center{
    text-align: center !important;
  }
  .formcontrol{
    width: 30%;
    margin: 1rem auto;
}


.dx-layout-manager .dx-field-item {
  font-size: 15px !important;
}
.dx-radiogroup-horizontal .dx-collection{
  background: #e9e9e9;
    margin-top: 1rem;
    border-radius: 10px;
}


$side-panel-min-width: 30px;
.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
  align-items: baseline !important;
}
.approval{
  background: none;
     box-shadow: none;
     border: 1px solid#185198;
     color:#185198;
     transition: .5s all ease-in-out;
}
.refused{
    background: none;
     box-shadow: none;
     border: 1px solid red;
     color: red;
     transition: .5s all ease-in-out;
}
.approval:hover{
  background:#185198;
   color: white;
}
.refused:hover{
background: red;
color: white;
}

.dx-designer .dx-designer-viewport .dx-editors .dx-editor .dx-field-label.dx-accordion-header{
  left: inherit !important;
}
.invalidCell {
  background-color: red !important;
  color: white !important;
}
.dx-datagrid-edit-popup .dx-popup-content{
  max-height: 750px !important;
}

.dx-editbox-input {  
  font-size: 8px!important;  
}
