/* You can add global styles to this file, and also import other style files */


  
  @import url("themes/generated/theme.additional.dark.css");
  @import url("themes/generated/theme.additional.light.css");
  @import url("themes/generated/theme.base.css");
  @import url("themes/generated/variables.additional.scss");
  @import url("themes/generated/variables.base.scss");
  @import url("themes/generated/variable.css"); 
  @import url("themes/generated/rtl.css"); 
  @import url("dx-styles.scss");

  .faspinner{
    display: flex;
      align-items: center;
      justify-content: center;
      font-size: 88px;
      height: 50vh;
  }
  
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
 /* Global adjustments for all dxForms */

  .lds-ripple div {
    position: absolute;
    border: 4px solid black;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
  @media screen and (max-width: 600px) {
  .title {
    font-size: 20px !important;
  }
  .hometitle {
    font-size: 13px !important;
  }

}

//fonts
.dx-tab-text ,.dx-header-row > td > .dx-datagrid-text-content,
.dx-field-item-label-text,.dx-header-row > td > .dx-treelist-text-content,
.dx-button-text,.dx-field-item-label-location-top .dx-field-item-custom-label-content
{
  font-size: 12px !important;
}
.dx-toolbar-label > div,.dx-treelist-nodata,.dx-form-group-caption{
  font-size: 15px !important;
}
.btn-primary {
  background-color: #007bff; /* Blue color */
  border-color: #007bff; /* Blue border */
  color: white; /* Text color */
  padding: 8px 16px; /* Padding for button */
  font-size: 14px; /* Font size */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
  border-color: #0056b3; /* Darker blue border on hover */
}

/* Remove Button (Danger) */
.btn-danger {
  background-color: #dc3545; /* Red color */
  border-color: #dc3545; /* Red border */
  color: white; /* Text color */
  padding: 8px 16px; /* Padding for button */
  font-size: 14px; /* Font size */
  border-radius: 4px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.btn-danger:hover {
  background-color: #c82333; /* Darker red on hover */
  border-color: #bd2130; /* Darker red border on hover */
}

/* Optional: Adjust layout of buttons */
.dxi-item button {
  margin: 10px 0; /* Space between buttons */
  display: inline-block; /* Align buttons properly */
  text-align: center; /* Center text */
  width: 100%; /* Button width to span full width */
  max-width: 200px; /* Max width of buttons */
}
